<div class="sidenav-scrollbar-container" fxLayout="column">
   <mat-nav-list fxLayout="column" menuToggleDirective class="navigation">
      <mat-list-item menuToggleLink *ngFor="let menuitem of menuItems.getAll()">
         <a menuToggle class="relative" mat-ripple [routerLink]="['/'+menuitem.state]" routerLinkActive="active-link" *ngIf="menuitem.type === 'link'">
         <mat-icon>{{ menuitem.icon }}</mat-icon>
         <span>{{ menuitem.name | translate }}</span>
         <ng-container *ngIf="menuitem.label">
            <span class="sidebar-label">{{ menuitem.label}}</span>
         </ng-container>
         </a>
         <a menuToggle class="relative" mat-ripple href="javascript:;" *ngIf="menuitem.type === 'sub'">
            <mat-icon>{{ menuitem.icon }}</mat-icon>
            <span>{{ menuitem.name | translate }}</span>
            <ng-container *ngIf="menuitem.label">
               <span class="sidebar-label">{{ menuitem.label}}</span>
            </ng-container>
            <span fxFlex></span>
            <mat-icon class="menu-caret">chevron_right</mat-icon>
         </a>
         <mat-nav-list class="sub-menu" *ngIf="menuitem.type === 'sub'">
            <mat-list-item *ngFor="let childitem of menuitem.children" routerLinkActive="open">
               <a [routerLink]="['/'+menuitem.state+'/'+childitem.state ]" routerLinkActive="active-link" class="relative" mat-ripple>
                  {{ childitem.name | translate }}
                  <ng-container *ngIf="childitem.label">
                     <span class="sidebar-label">{{childitem.label}}</span>
                  </ng-container>
               </a>
            </mat-list-item>
         </mat-nav-list>
      </mat-list-item>
      <mat-divider></mat-divider>
   </mat-nav-list>
</div>
<!-- sidenav-scrollbar-container -->