<form [formGroup]="addPartnerForm" class="popup-card-width add-new-user">
	<div>
		<h2 mat-dialog-title>{{data ? 'Update' : 'Add New'}} Partner</h2>
	</div>
	<mat-dialog-content class="mb-4">
		<div>
			<div>
				<mat-form-field class="w-100">
					<input matInput type="text" formControlName="name" class="form-control" placeholder="Name">
					<mat-error *ngIf="addPartnerForm.controls['name'].touched && !addPartnerForm.controls['name'].valid">
						<mat-error *ngIf="addPartnerForm.controls['name'].hasError('required')" class="required alert-error">  
							You must include a Name.
						</mat-error>
		         </mat-error>
				</mat-form-field>

             <mat-form-field class="w-100">
                <input matInput type="text" formControlName="description" class="form-control" placeholder="Description">
                <mat-error *ngIf="addPartnerForm.controls['description'].touched && !addPartnerForm.controls['description'].valid">
                   <mat-error *ngIf="addPartnerForm.controls['description'].hasError('required')" class="required alert-error">  
                      You must include a Description.
                   </mat-error> 
                </mat-error>
             </mat-form-field> 

             <div class="mb-1">
               <div style="display: inline-block; margin-right: 5px;">
                  <button mat-raised-button type="button" (click)="logoFileInput.click()">
                     <mat-icon>image</mat-icon>
                     {{logoImageUrl ? 'Change' : 'Add a'}} Logo <span style="color: red;">*</span>
                  </button>
                  <input hidden type="file" formControlName="logo" #logoFileInput (change)="previewImage($event, 'logo')"/>
                  <div *ngIf="logoImageUrl" class="images">
                     <img class="frame" [src]="logoImageUrl">
                  </div>
               </div>
               <div style="float: right;">
                  <button mat-raised-button type="button" (click)="bgFileInput.click()">
                     <mat-icon>image</mat-icon>
                     {{backgroundImageUrl ? 'Change' : 'Add a'}} Background <span style="color: red;">*</span>
                  </button>
                  <input hidden type="file" formControlName="backgroundImage" #bgFileInput (change)="previewImage($event, 'backgroundImage')"/>
                  <div *ngIf="backgroundImageUrl" class="images">
                     <img class="frame" [src]="backgroundImageUrl">
                  </div>
               </div>                
             </div>     
			</div>
      </div>
	</mat-dialog-content>
	<mat-dialog-actions class="m-0">
		<button type="button" mat-raised-button (click)="dialogRef.close()" color="primary">CLOSE</button>
		<button (click)="onFormSubmit()" type="submit" [disabled]="!addPartnerForm.valid || loading" mat-raised-button color="warn"
            [class.spinner]="loading">
         SUBMIT
      </button>
	</mat-dialog-actions>
</form>
<!-- add-new-partner -->