<mat-card class="p-0">
  <div fxLayout='row wrap' fxLayoutGap="0px" class="feature-list">
    <div fxFlex.xs="100" fxFlex.sm="100" fxFlex.md="33.33" fxFlex.lg="33.33" fxFlex.xl="33.33">
      <div class="feature-inner p-4">
        <div fxLayout="row" fxLayoutAlign="start center" >
          <div>
            <img src="assets/images/free-delivery.png" alt="Feature of embryo" width="50" height="50">
          </div>
          <div class="px-4">
            <h6 class="text-md">FREE SHIPPING</h6>
            <h5 class="text-lg">Every Day, Every Order</h5>
            <span class="text-muted text-md">*All rder over $100</span>
          </div>
        </div>
      </div>
    </div>
    <div fxFlex.xs="100" fxFlex.sm="100" fxFlex.md="33.33" fxFlex.lg="33.33" fxFlex.xl="33.33">
      <div class="feature-inner p-4">
        <div fxLayout="row" fxLayoutAlign="start center" >
          <div>
            <img src="assets/images/customer-support.png" alt="Feature of embryo" width="50" height="50">
          </div>
          <div class="px-4">
            <h6 class="text-md">FRIENDLY SUPPORT</h6>
            <h5 class="text-lg">24/7 Dedicated Support</h5>
            <span class="text-muted text-md">*Only In USA</span>
          </div>
        </div>
      </div>
    </div>
    <div fxFlex.xs="100" fxFlex.sm="100" fxFlex.md="33.33" fxFlex.lg="33.33" fxFlex.xl="33.33">
      <div class="feature-inner p-4">
        <div fxLayout="row" fxLayoutAlign="start center">
          <div>
            <img src="assets/images/money-back.png" alt="Feature of embryo" width="50" height="50">
          </div>
          <div class="px-4">
            <h6 class="text-md">SECURE</h6>
            <h5 class="text-lg">Money Back Guranteed</h5>
            <span class="text-muted text-md">*Conditions Apply</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-card>
