import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastaService } from 'ngx-toasta';
import { AuthService } from 'src/app/Services/auth.service';

@Component({
	selector: 'embryo-header-user-profile',
	templateUrl: './HeaderUserProfileDropdown.component.html',
	styleUrls: ['./HeaderUserProfileDropdown.component.scss']
})

export class HeaderUserProfileDropdownComponent implements OnInit {
	userInfo;
	letters;
	constructor(public router : Router,private authService:AuthService,private toastService:ToastaService) {}

	ngOnInit() {
		this.authService.getUserDetails().subscribe((user:any)=>{
			if(user){
			  this.userInfo = user;
			  this.letters = `${user.firstName[0]}${user.lastName[0]}`
			}
		  })
	}

	//log out method 
	logOut(){
		// Logout added
		this.authService.signOut().then(async () => {
			await this.router.navigate(['/session/signin']).then(() => {
				this.toastService.success({
					title: "Success",
					msg: "User Logged Out!",
					showClose: true,
					timeout: 2000,
				  });
			});
			
			document.getElementById('html').classList.remove("admin-panel");
		})
		
	}
}
