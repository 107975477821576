
<embryo-PageTitle heading="Here’s what’s in your bag." subHeading="Our latest news and learning articles."></embryo-PageTitle>

<div class="inner-container bg-white cart-page section-gap" *ngIf="(embryoService.localStorageCartProducts && embryoService.localStorageCartProducts.length>0); else elseBlock">
  <div class="container">
	 <div class="cart-wrapper">
		<div class="cart-shop-list mb-5 text-center  card-shadow">
		  <div fxLayout='row wrap' fxLayoutAlign="start center" class="cart-item" *ngFor="let product of embryoService.localStorageCartProducts">
			 <div fxFlex.xs="100" fxFlex.sm="100" fxFlex.md="10" fxFlex.lg="20" fxFlex.xl="20" class="cart-product-image">
				<div class="cart-item-thumb">
				  <a href="javascript:void(0)" class="p-link">
					 <img width="626" height="800" class="card-shadow" [src]="product.image" alt="cart image">
				  </a>
				</div>
			 </div>
			 <div fxFlex.xs="100" fxFlex.sm="100" fxFlex.md="90" fxFlex.lg="80" fxFlex.xl="80">
				<div fxLayout='row wrap' fxLayoutAlign="center center" class="cart-product-info">
				  <div fxFlex.xs="100" fxFlex.sm="40" fxFlex.md="30" fxFlex.lg="40" fxFlex.xl="40">
					 <h5>{{product?.name}}</h5>
					 <p class="mb-0">Delivery in 3-4 days | Free</p>
					 <p> 10 Days Replacement Policy</p>
				  </div>
				  <div fxFlex.xs="50" fxFlex.sm="20" fxFlex.md="20" fxFlex.lg="20" fxFlex.xl="20">
					 <mat-form-field class="w-100">
						<input  matInput type="number" #quantity [value]="getQuantityValue(product)" min="1" max="10" placeholder="Quantity" (input)="onChange($event.target.value, product)">
					 </mat-form-field>
				  </div>
				  <div fxFlex.xs="50" fxFlex.sm="40" fxFlex.md="30" fxFlex.lg="20" fxFlex.xl="20">
					 <div class="cart-item-price">
						<h4>{{ product.price | currency:embryoService?.currency}}</h4>
					 </div>
				  </div>
				  <div class="close-sm" fxFlex.xs="100" fxFlex.sm="100" fxFlex.md="20" fxFlex.lg="20" fxFlex.xl="20">
					 <div class="cart-item-price">
						<a href="javascript:void(0)" class="accent-color remove-cart" (click)="removeProduct(product)"><i class="material-icons">
							 close
						  </i></a>
					 </div>
				  </div>
				</div>
			 </div>
		  </div>
		</div>
		<div class="cart-total" fxLayout='row wrap' fxLayoutAlign="flex-end">
		  <div fxFlex.xs="100" fxFlex.sm="100" fxFlex.md="40" fxFlex.lg="40" fxFlex.xl="40">
			 <div>
				<p>Subtotal</p><span fxFlex></span> {{calculateTotalPrice() | currency:embryoService?.currency}}
			 </div>
			 <div>
				<p>Shipping</p><span fxFlex></span> {{embryoService.shipping | currency:embryoService?.currency}}
			 </div>
			 <div>
				<p>Tax(GST)</p><span fxFlex></span> {{embryoService.tax | currency:embryoService?.currency}}
			 </div>
			 <hr class="spacer">
			 <div class="mb-4">
				<h4>Total</h4><span fxFlex></span>
				<h4> {{getTotalPrice() | currency:embryoService?.currency}}</h4>
			 </div>
			 <div> <span fxFlex></span><button mat-raised-button color="accent" class="button-lg" (click)="updateLocalCartProduct()">Checkout</button></div>
		  </div>
		</div>
	 </div>
  </div>
</div>

<ng-template #elseBlock>
  <div class="section-gap-lg text-center">
	 <div class="mb-4">
	 <img src="assets/images/empty-cart.png" height="128" width="128" alt="cart-empty">
  </div>
	 <h4> Your Shopping Bag is empty.</h4>
	 <a href="javascript:void(0)" class="primary-color" [routerLink]="['/']">Go for Shopping</a>
  </div>
</ng-template>
