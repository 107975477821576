
<button mat-mini-fab color="primary" class="mr-1">
  <i class="fab fa-facebook-f"></i>
</button>
<button mat-mini-fab color="primary" class="mr-1">
  <i class="fab fa-twitter"></i>
</button>
<button mat-mini-fab color="primary" class="mr-1">
  <i class="fab fa-google-plus-g"></i>
</button>
<button mat-mini-fab color="primary" class="mr-1">
  <i class="fab fa-instagram"></i>
</button>
