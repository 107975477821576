import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { AdminPanelServiceService } from 'src/app/AdminPanel/Service/AdminPanelService.service';

@Component({
  selector: 'ms-add-new-client',
  templateUrl: './add-partner.component.html',
  styleUrls: ['./add-partner.component.css']
})
export class AddPartnerComponent implements OnInit {

	addPartnerForm    : FormGroup;

	logoImage: File;
	logoImageUrl: SafeUrl;
	backgroundImage: File;
	backgroundImageUrl: SafeUrl;

	loading: boolean = false;

	constructor(
		private formBuilder : FormBuilder,
		public dialogRef    : MatDialogRef<AddPartnerComponent>,
		private sanitizer: DomSanitizer,
		@Inject(MAT_DIALOG_DATA) public data: any, 
		private service:AdminPanelServiceService) { }
// 'name', 'logo', 'backgroundImage','description','status'
	ngOnInit() {
    // const {name = false,logo  = false,backgroundImage  = false,description  = false,status = 'active'} = this.data;
    console.log("TEST",this.data)
		this.addPartnerForm = this.formBuilder.group({
			name: [this.data ? this.data.name  :'',[Validators.required]],
			logo: [this.data ? this.data.logoUrl  :'',[Validators.required,]],
			backgroundImage: [this.data ? this.data.backgroundUrl  :'',[Validators.required]],
			description:[this.data ? this.data.description  :'',[Validators.required]],
			status:[this.data ? this.data.status : 'active']
		});

		this.logoImageUrl = this.data.logoUrl;
		this.backgroundImageUrl = this.data.backgroundUrl;
	}

	// onFormSubmit method is submit a add new user form.
	async onFormSubmit() {
		console.log("Value",this.data,this.addPartnerForm.value);

		const formData = this.addPartnerForm.value;		
		this.loading = true;
		
		if(this.data) {
			try {
				// If images have been changed, re-upload.
				if (this.logoImage) {
					const logoUrl = await this.service.uploadFile(this.logoImage, `partners/${formData.name.split(' ').join('-')}`);
					formData.logoUrl = logoUrl;
				}
				if (this.backgroundImage) {
					const backgroundUrl = await this.service.uploadFile(this.backgroundImage, `partners/${formData.name.split(' ').join('-')}`);
					formData.backgroundUrl = backgroundUrl;
				}

				// Delete local file paths from data
				delete formData['logo'];
				delete formData['backgroundImage'];

				this.service.updatePartner(this.data.key, formData);
			} catch (err) {
				alert(err);
				console.log(err);
			}			
		} else {			
			try {
				// Upload Files: 
				const logoUrl = await this.service.uploadFile(this.logoImage, `partners/${formData.name.split(' ').join('-')}`);
				const backgroundUrl = await this.service.uploadFile(this.backgroundImage, `partners/${formData.name.split(' ').join('-')}`);
				
				// Delete local file paths from data
				delete formData['logo'];
				delete formData['backgroundImage'];

				// Add Data:
				this.service.addPartner({
					...formData,
					logoUrl,
					backgroundUrl
				});
			} catch (err) {
				alert(err);
				console.log(err);
			}	
		}
		
		this.loading = false;
		this.dialogRef.close();
	}

	async previewImage(event, type) {
		const file = event.currentTarget.files[0];
		const unsafeImageUrl = URL.createObjectURL(file);
        const imageUrl = this.sanitizer.bypassSecurityTrustUrl(unsafeImageUrl);
		if (type === 'logo') {
			this.logoImage = file;
			this.logoImageUrl = imageUrl;
		} else {
			this.backgroundImage = file;
			this.backgroundImageUrl = imageUrl;
		}
	}
}