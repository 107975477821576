<form [formGroup]="addFaqForm" class="popup-card-width add-new-user">
	<div>
		<h2 mat-dialog-title>Add New FAQ</h2>
	</div>	
	<mat-dialog-content class="mb-4">
		<div>
			<div>
				<mat-form-field class="w-100">
					<input matInput type="text" formControlName="ques" class="form-control" placeholder="Question">
					<mat-error *ngIf="addFaqForm.controls['ques'].touched && !addFaqForm.controls['ques'].valid">
						<mat-error *ngIf="addFaqForm.controls['ques'].hasError('required')" class="required alert-error">  
							You must include a Question.
						</mat-error>
		         </mat-error>
				</mat-form-field>
				<mat-form-field class="w-100">
					<input matInput type="text" formControlName="ans" class="form-control" placeholder="Answer">
					<mat-error *ngIf="addFaqForm.controls['ans'].touched && !addFaqForm.controls['ans'].valid">
						<mat-error *ngIf="addFaqForm.controls['ans'].hasError('required')" class="required alert-error">  
							You must include a Answer.
						</mat-error>
		         </mat-error>
				</mat-form-field>
			</div>
      </div>
	</mat-dialog-content>
	<mat-dialog-actions class="m-0">
		<button type="button" mat-raised-button (click)="dialogRef.close()" color="primary">CLOSE</button>
		<button (click)="onFormSubmit(data)" type="submit" [disabled]="!addFaqForm.valid" mat-raised-button color="warn">SUBMIT</button>
	</mat-dialog-actions>
</form>
<!-- add-new-user -->