<div class="sidebar-panel embryo-sidenav" fxLayout="column">
   <perfect-scrollbar class="scrollbar" [config]="config">
      <div class="sidebar-container">
      	<div class="p-4">
      		<embryo-AppLogo></embryo-AppLogo>
      	</div>
         <div class="mb-5 mt-3"></div>
        	<app-menu-list-items></app-menu-list-items>
      </div>
   </perfect-scrollbar>
</div>
<!-- sidebar-panel and embryo-sidenav -->