<ng-container *ngIf="(mobWidth >= mobScreenSize) else elseButtonBlock">
   <button mat-mini-fab [matMenuTriggerFor]="viewdropmenu" [matBadgeHidden]="hiddenBadge" matBadge="   
   {{count}}" matBadgePosition="after" matBadgeColor="accent">
      <i class="material-icons notranslate">
         shopping_cart
      </i>
   </button>
   <mat-menu #viewdropmenu="matMenu">
      <ng-container *ngIf="(cartProducts && cartProducts.length > 0); else elseBlock">
         <div class="drop-wrap">
            <div fxFlex="row wrap" class="cart-menu-list p-2" fxLayoutAlign="start center" *ngFor="let product of cartProducts;">
               <div fxFlex.xs="25" fxFlex.sm="25" fxFlex.md="25" fxFlex.lg="25" fxFlex.xl="25"> <a href="
               javascript:void(0);"><img [src]="product?.image" width="60" alt="cart-image"></a> </div>
               <div fxFlex.xs="75" fxFlex.sm="75" fxFlex.md="75" fxFlex.lg="75" fxFlex.xl="75" class="px-2">
                  <h6 class="mb-0"><a href="javascript:void(0);">{{product?.name}}</a></h6>
                  <span>{{calculatePrice(product) | currency:currency }}</span>
               </div>
               <div fxFlex.xs="35" fxFlex.sm="35" fxFlex.md="35" fxFlex.lg="35" fxFlex.xl="35" class="
               cart-menu-action">
                  <button mat-mini-fab mat-raised-button color="primary" (click)="confirmationPopup(product)"><i
                  class="material-icons">
                    remove_shopping_cart
                  </i> </button>
                  <button mat-mini-fab mat-raised-button color="primary" [routerLink]="['/cart']"><i class="material-icons">
                    edit
                  </i></button>
               </div>
            </div>
         </div>
         <div class="footer-menu px-3 pb-2 pt-2">
            <button mat-raised-button color="accent" class="w-100" [routerLink]="['/checkout']">Checkout</button>
         </div>
      </ng-container>
      <ng-template #elseBlock>
         <div class="cart-menu-list p-2" fxLayoutAlign="center center">
            No Product Found.
         </div>
      </ng-template>
   </mat-menu>
</ng-container>
<ng-template #elseButtonBlock>
   <button mat-mini-fab [matBadgeHidden]="hiddenBadge" matBadge="{{count}}" matBadgePosition="after" matBadgeColor="accent" [routerLink]="['/cart']">
      <i class="material-icons notranslate">
        shopping_cart
      </i>
  </button>
</ng-template>