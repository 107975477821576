<div class="section-gap pt-0">
  <div class="container">
    <div class="cta text-center" style="background-image: url('assets/images/cta-bg-pat.png');padding: 90px 0; background-size:25px;">
      <div class="overlay-section-overlay cta-image  relative">
        <img src="assets/images/bg-sunglass.jpg" alt="">
        <div class="cta-content">

          <p><i class="material-icons text-inverse">
              brightness_5
            </i>
         </p>
          <h4 class="font-bold mb-4 text-inverse">new arrival</h4>
          <h2 class="mb-4 text-inverse">Sunglasses for Everyone</h2>
          <button mat-raised-button [routerLink]="['/products']">SHOP NOW</button>
        </div>
      </div>
    </div>
  </div>
</div>