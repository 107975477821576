import { Injectable } from '@angular/core';

export interface ChildrenItems {
  state: string;
  name: string;
  type?: string;
}

export interface Menu {
  state: string;
  name: string;
  type: string;
  icon: string;
  children?: ChildrenItems[];
}

const MENUITEMS = [
  {
    state: 'admin-panel/reports',
    name: 'Reports',
    type: 'link',
    icon: 'poll'
  },
  {
    state: 'admin-panel/invoices',
    name: 'Invoices',
    type: 'link',
    icon: 'recent_actors'
  },
  {
    state: 'admin-panel',
    name: 'Store',
    type: 'sub',
    icon: 'shopping_cart',
    children: [
      {state: 'partners', name: 'Partners',type : 'link'},
      {state: 'products', name: 'Products',type : 'link'},
      {state: 'windows', name: 'Windows',type : 'link'},
      // {state: 'product-add', name: 'Product Add',type : 'link'}
    ]
  },
  {
    state: 'admin-panel',
    name: 'Pages',
    type: 'sub',
    icon: 'shopping_cart',
    children: [
      {state: 'pages/homePage', name: 'Home',type : 'link'},
      {state: 'pages/contact', name: 'Contact',type : 'link'},
      {state: 'pages/about', name: 'About',type : 'link'},
      {state: 'pages/mission', name: 'Mission',type : 'link'},
      {state: 'pages/privacy', name: 'Privacy Policy',type : 'link'},
      {state: 'pages/team', name: 'Team',type : 'link'},
      {state: 'pages/terms', name: 'T & C',type : 'link'},
      {state: 'pages/testimonials', name: 'Testimonials',type : 'link'},
      {state: 'pages/faqs', name: 'FAQs',type : 'link'}
    ]
  },
  {
    state: 'admin-panel/usersList',
    name: 'Users',
    type: 'link',
    icon: 'recent_actors'
  },
  {
    state: 'admin-panel/account/profile',
    name: 'Profile',
    type: 'link',
    icon: 'account_circle'
  },
  {
    state: '/',
    name: 'Go To Site',
    type: 'link',
    icon: 'home'
  }
];

@Injectable()
export class AdminMenuItems {
  getAll(): Menu[] {
    return MENUITEMS;
  }
}
