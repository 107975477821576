import { Routes} from '@angular/router';

import { MainComponent } from './Main/Main.component';
import { HomeComponent } from './Pages/Home/Home.component';
import { CartComponent } from './Pages/Cart/Cart.component';
import { NotFoundComponent } from './Pages/NotFound/NotFound.component';

export const AppRoutes : Routes = [
   {
      path : '',
      redirectTo: 'home',
      pathMatch: 'full',
   },
   {
      path : '',
      component : MainComponent,
      children: [ 
         {
            path : '',
            component : HomeComponent
         },
         {
            path: 'products',loadChildren: ()=>
            import('./Pages/Products/Products.module').then (m => m.ProductsModule)
         },
         {
            path: 'cart',
            component: CartComponent
         },
         {
          path: 'not-found',
          component: NotFoundComponent
         },
         {
            path: 'session',loadChildren: ()=>
            import('./Pages/Session/Session.module').then (m => m.SessionModule)
         },
         {
            path: 'checkout',loadChildren: ()=>
            import('./Pages/Checkout/Checkout.module').then (m => m.CheckoutModule)
         },
         {
            path: '',loadChildren: ()=>
            import('./Pages/About/About.module').then( m=> m.AboutModule)
         },
         {
            path: 'blogs',loadChildren: ()=>
            import('./Pages/Blogs/Blogs.module').then (m => m.BlogsModule)
         },
         {
            path: 'account',loadChildren: ()=>
            import('./Pages/UserAccount/UserAccount.module').then (m => m.UserAccountModule)
         },
         {
            path: 'partners', loadChildren: ()=>
            import('./Pages/partner/partner.module').then (m => m.PartnerModule)
         }
      ]
   },
   {
      path: '**',
      redirectTo: 'not-found'
   }
]
