import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { DeleteListDialogComponent} from '../Widget/PopUp/DeleteListDialog/DeleteListDialog.component';
import { DeleteUserDialogComponent} from '../Widget/PopUp/DeleteUserDialog/DeleteDialog.component';
import { SeeListDialogComponent } from '../Widget/PopUp/SeeListDialog/SeeListDialog.component';
import { AddNewUserComponent } from '../Widget/PopUp/AddNewUser/AddNewUser.component';
import { AngularFireDatabase, AngularFireList, AngularFireObject } from "@angular/fire/database";
import { AngularFirestore } from '@angular/fire/firestore';
import { AdminAddUserComponent } from '../Widget/PopUp/AdminAddUser/AdminAddUser.component';
import { AuthService } from 'src/app/Services/auth.service';
import { ToastaService } from 'ngx-toasta';
import { AngularFireAuth } from '@angular/fire/auth';
import { AddPartnerComponent } from '../Widget/PopUp/AddPartner/add-partner.component';
import { AngularFireStorage } from "@angular/fire/storage";
import * as moment from 'moment-timezone';
import { take } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { DateTime } from "luxon";

@Injectable({
  providedIn: 'root'
})
export class AdminPanelServiceService {

	sidenavOpen: boolean = true;
	sidenavMode: string = "side";
	chatSideBarOpen: boolean = true;
	editProductData: any;
	products: AngularFireObject < any > ;

	constructor(private http: HttpClient,
		private dialog: MatDialog,
		private db: AngularFireDatabase, 
		private angularFireStore: AngularFirestore, 
		private firebaseStorage: AngularFireStorage,
		private auth: AuthService, 
		private authServ: AngularFireAuth, 
		private toastService: ToastaService) {			
		}

	/*
		---------- Pop Up Function ----------
	*/

	//deleteDiaglog function is used to open the Delete Dialog Component.
	deleteDialog(data: string) {
		let dialogRef: MatDialogRef < DeleteListDialogComponent > ;
		dialogRef = this.dialog.open(DeleteListDialogComponent);
		dialogRef.componentInstance.data = data;

		return dialogRef.afterClosed();
	}

	//getProducts method is used to get the products.
	public getProducts(partnerKey = null, limit = null) {
		if (partnerKey) {
			if (limit) {
				return this.db.list("products", ref => ref.orderByChild("partner").equalTo(partnerKey).limitToFirst(limit));
			} else {
				return this.db.list("products", ref => ref.orderByChild("partner").equalTo(partnerKey));
			}			;
		} else {
			return this.db.list("products");
		}			
	}

	public addProduct(product: any) {
		const ref = this.db.list("products");
		return ref.push(product).then(async (res) =>{
			await ref.update(res.key, {key: res.key});
			this.toastService.success({
						title: "Success",
						msg: "Product added successfully!",
						showClose: true,
						timeout: 3000,
					});
			}).catch((err) => {
				this.toastService.error({
					title: "Error",
					msg: err.message,
					showClose: true,
					timeout: 3000,
				});
			});
	}

	//getTableTabContent method is used to get the transcation table data.
	getTableTabContent() {
		let tableContent: any;
		tableContent = this.db.object("transcationTable");
		return tableContent;
	}

	//getBuySellChartData method is used to get the buy and sell chart data.
	getBuySellChartContent() {
		let buySellChart: any;
		buySellChart = this.db.list("buySellChartData");
		return buySellChart;
	}

	//getInvoiceContent method is used to get the Invoice table data.
	getInvoiceContent() {
		let invoiceList: any;
		invoiceList = this.db.list("invoiceData");
		return invoiceList;
	}

	//getCollaborationContent method is used to get the Collaboration table data.
	getCollaborationContent() {
		let collaboration: any;
		collaboration = this.db.list("collaborationData");
		return collaboration;
	}

	//seeList function is used to open the see Dialog Component.
	seeList() {
		let dialogRef: MatDialogRef < SeeListDialogComponent > ;
		dialogRef = this.dialog.open(SeeListDialogComponent);
	}

	//addNewUserDialog function is used to open Add new user Dialog Component.
	addNewUserDialog() {
		let dialogRef: MatDialogRef < AddNewUserComponent > ;
		dialogRef = this.dialog.open(AddNewUserComponent);

		return dialogRef.afterClosed();
	}

	addUserDialogue(data: any) {
		let dialogRef: MatDialogRef < AdminAddUserComponent > ;
		dialogRef = data ? this.dialog.open(AdminAddUserComponent, {
			data
		}) : this.dialog.open(AdminAddUserComponent);
		return dialogRef.afterClosed();
	}

	getUsersList() {
		let users: any;
		users = this.db.list("users");
		console.log("USERS", users)
		return users;
	}

	async createNewUser(userData) {
		await this.auth.signUpUser(userData).toPromise()
	}

	createUser(userData: any) {
		const resp = this.createNewUser(userData);

		resp.then(re => {
			this.getUsersList().snapshotChanges().subscribe(res => {
				const user = res.map(a => {
					const data = a.payload.val();
					const key = a.payload.key;
					return data.email === userData.email ? {
						key,
						...data
					} : false; // or {key, ...data} in case data is Obj
				}).filter(Boolean)[0];
				//   console.log("New Users",user);
				let users: any;
				users = this.db.list("users");
				users.set(user.key, {
					...userData,
					enabled: 'Yes'
				});
				// this.toastService.success({
				// 	title: "Success",
				// 	msg: "User added successfully!",
				// 	showClose: true,
				// 	timeout: 3000,
				// });
			})

		}).catch(err => {
			this.toastService.error({
				title: "Error",
				msg: err.message,
				showClose: true,
				timeout: 3000,
			});
		})

	}

	deleteUser(user) {
		let users: any;
		users = this.db.list("users");
		// users.remove(user.key);
		users.set(user.key, {
			...user,
			enabled: 'No'
		});
	}

	deleteUserDialog(data: string, data1: any) {
		let dialogRef: MatDialogRef < DeleteUserDialogComponent > ;
		dialogRef = this.dialog.open(DeleteUserDialogComponent);
		dialogRef.componentInstance.data = data;
		dialogRef.componentInstance.data1 = data1;

		return dialogRef.afterClosed();
	}

	updateUser(field: string, obj: any) {
		let users;
		users = this.db.list("users");
		try {
			delete obj['key'];
			users.set(field, obj);
		} catch (err) {
			console.log("ERROR", err);
			this.toastService.error({
				title: "Error",
				msg: "Error!",
				showClose: true,
				timeout: 3000,
			});
		}
	}

	//getPartners method is used to get the partners.
	public getPartners() {
		const partners = this.db.list("partners");
		return partners.snapshotChanges();
	}

	getPartner(partnerKey: string) {
		return this.db.object(`partners/${partnerKey}`);
	}

	addPartnerDialogue(data: any) {
		let dialogRef: MatDialogRef < AddPartnerComponent > ;
		dialogRef = data ? this.dialog.open(AddPartnerComponent, {
			data
		}) : this.dialog.open(AddPartnerComponent);
		return dialogRef.afterClosed();
	}

	// Added by Tahir @ 25/10/21
	// Upload File to Firebase Storage
	// Takes params of File and folder path (optional)
	// Returns URL of uploaded image
	async uploadFile(file: File, folder: string = null) {
		const now = Date.now();
		const filePath = `${folder}/${file.name}`;
		const fileRef = this.firebaseStorage.ref(filePath);
		try {
			await this.firebaseStorage.upload(filePath, file);
			const downloadURL = await fileRef.getDownloadURL().toPromise();
			return downloadURL;
		} catch(err) {
			console.log(err);
			return null;
		}
	}

	// Window Functions ------
	addWindow(data: any) {
		const windows = this.db.list("windows");
		return windows.push(data);
	}

	updateWindow(key: string, data: any) {
		const windows = this.db.list("windows");
		return windows.update(key, data);
	}

	getWindows(dateTime?: number) {
		let windows;
		if (dateTime) {	
			windows = this.db.list("windows", ref => ref.orderByChild("startDate").startAt(dateTime));
		} else {
			windows = this.db.list("windows");
		}		
		return windows.snapshotChanges();
	}

	async getNextWindow() {
		const today = DateTime.now().setZone('America/Chicago').minus({days:1}).toSeconds();
		console.log('Next window: ' + today);
		
		const windows = await this.getWindows(today).pipe(take(1)).toPromise();
		return windows[0];
	}

	deleteWindow(key) {
		const windows = this.db.list("windows");
        return windows.remove(key).then(() =>{
			this.toastService.success({
						title: "Success",
						msg: "Window deleted successfully!",
						showClose: true,
						timeout: 3000,
					});
			}).catch((err) => {
				this.toastService.error({
					title: "Error",
					msg: err.message,
					showClose: true,
					timeout: 3000,
				});
			});
	}

	isStoreOpenBehaviour = new BehaviorSubject(false);

	async isStoreOpen() {
		console.log('Checking if the store is open');
		
		this.getWindows().subscribe((windows: any) => {
			this.isStoreOpenBehaviour.next(false);
			// Get current date time for CST
			const today = DateTime.now().setZone('America/Chicago');			
   
			for (let i = 0; i < windows.length; i++) {
			   const window = windows[i].payload.val();
			   
			   const startMoment = DateTime.fromSeconds(window.startDate)
				.setZone('utc')
				.setZone('America/Chicago')
				.set({
						hour: window.startTime.split(':')[0],
						minute: window.startTime.split(':')[1]
					});  

			   const endMoment = DateTime.fromSeconds(window.endDate)
			   	.setZone('utc')
				.setZone('America/Chicago').set({
					hour: window.endTime.split(':')[0],
					minute: window.endTime.split(':')[1]
				});
				
			   if (today >= startMoment && today <= endMoment) {			   
					this.isStoreOpenBehaviour.next(true);	  
					break;
			   }
			}

		 }, err => {
			this.isStoreOpenBehaviour.next(false);
		 });
	}

	addPartner(value:any) {
		const partners = this.db.list("partners");
		partners.push(value).then(() =>{
		this.toastService.success({
					title: "Success",
					msg: "Partner added successfully!",
					showClose: true,
					timeout: 3000,
				});
		}).catch((err) => {
			this.toastService.error({
				title: "Error",
				msg: err.message,
				showClose: true,
				timeout: 3000,
			});
		});
		return partners.snapshotChanges();
	}

	updatePartner(key,partner) {
		const partners = this.db.list("partners");
          partners.update(key, partner).then(() =>{
			this.toastService.success({
						title: "Success",
						msg: "Partner updated successfully!",
						showClose: true,
						timeout: 3000,
					});
			}).catch((err) => {
				this.toastService.error({
					title: "Error",
					msg: err.message,
					showClose: true,
					timeout: 3000,
				});
			});
		return partners.snapshotChanges();
	}

	deletePartnerDialogue(data: string, data1: any,data2?:any) {
		let dialogRef: MatDialogRef < DeleteUserDialogComponent > ;
		dialogRef = this.dialog.open(DeleteUserDialogComponent);
		dialogRef.componentInstance.data = data;
		dialogRef.componentInstance.data1 = data1;
		dialogRef.componentInstance.allValues = data2;
		return dialogRef.afterClosed();
	}
	public deletePartner(key) {
		const partners = this.db.list("partners");
          partners.remove(key).then(() =>{
			this.toastService.success({
						title: "Success",
						msg: "Partner deleted successfully!",
						showClose: true,
						timeout: 3000,
					});
			}).catch((err) => {
				this.toastService.error({
					title: "Error",
					msg: err.message,
					showClose: true,
					timeout: 3000,
				});
			});
		return partners.snapshotChanges();
	}

	editWindowCountdownDetails(data: any) {
		const windows = this.db.object('home/windowCountDown');
		windows.set(data).then(resp => {
			// console.log("RESP",resp)
		}).catch(err => {
			// console.log("ERROR",err);
		})
		// return windows.push(data);
	}

	getWindowCountDown () {
		return this.db.object('home/windowCountDown').snapshotChanges()
	}
}
