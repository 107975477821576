import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PageService } from 'src/app/AdminPanel/Pages/Services/pages.service';

@Component({
  selector: 'ms-add-new-client',
  templateUrl: './AddFaq.component.html',
  styleUrls: ['./AddFaq.component.scss']
})
export class AddFAQComponent implements OnInit {

	addFaqForm    : FormGroup;
    alVals:any;
	constructor( private formBuilder : FormBuilder,
					 public dialogRef    : MatDialogRef<AddFAQComponent>,
					 public pageService : PageService,
					 @Inject(MAT_DIALOG_DATA) public data: any 
					 ) { }

	ngOnInit() {
		this.pageService.getFaqs().snapshotChanges().subscribe(resp => this.saveAll(resp));
		this.addFaqForm = this.formBuilder.group({
			ans 	     : [ '',[Validators.required]],
			ques: ['',[Validators.required]],
		});
		this.alVals = this.data;
		// console.log("INIT",this.data);
		
	}

	saveAll(resp) {
		const faqs = resp.map(a => {
			const data = a.payload.val();
			const key = a.payload.key;
			// console.log("MAP",key,data)
			return key ==="faqs" && Array.isArray(data) ? data : [];           // or {key, ...data} in case data is Obj
		  }).filter(Boolean)[0];
		  // console.log("Vals",faqs);
		  this.data = faqs;
	}

	// onFormSubmit method is submit a add new user form.
	onFormSubmit(data1){
		
		// let faqs = this.data && this.data.length > 0 ? [...this.data] : [];
		// faqs.push(this.addFaqForm.value);
		// console.log("ON SUBMIT",this.alVals,this.addFaqForm.value);
		let updatedValues = [...this.alVals,this.addFaqForm.value]
		this.pageService.addFaq('faqs',updatedValues)
		this.dialogRef.close();
	}
}