// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
	production: false,
	firebase: {
		apiKey: "AIzaSyDEVvRi1vL-yks1BFJVcNB9Am26-B-VRxw",
		authDomain: "vanalstynecoop-4b3ee.firebaseapp.com",
		projectId: "vanalstynecoop-4b3ee",
		storageBucket: "vanalstynecoop-4b3ee.appspot.com",
		messagingSenderId: "840855748207",
		appId: "1:840855748207:web:ad2240aca2d0249307179a",
		measurementId: "G-6WTR79YY79"
	},
	stripe: 'pk_test_eedQ5Ia89zwtus79tZnswZ1H',
	createStripeCustomer: 'https://us-central1-vanalstynecoop-4b3ee.cloudfunctions.net/createStripeCustomer'
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
