<div class="section-gap pt-4 overlay-section">
    <div class="container">
      <div fxLayout="row wrap" class="col-gap">
        <div fxFlex.xs="100" fxFlex.sm="100" fxFlex.md="33" fxFlex.lg="33" fxFlex.xl="33">
          <div class="main-bg overlay-section-item overlay-section-overlay relative" style="background-image: url('assets/images/collection.jpg');background-size:cover;">
            <div class="end-left">
              <div class="overlay-section-content">
                <h4 class="mb-0 font-bold">Latest Collections</h4>
                <h4 class=" text-xxl font-normal mb-3">Save Upto 60%</h4>
                <button mat-raised-button [routerLink]="['/products']">SHOP NOW</button>
              </div>
            </div>
          </div>
        </div>
        <div fxFlex.xs="100" fxFlex.sm="100" fxFlex.md="66" fxFlex.lg="66" fxFlex.xl="66" class="m-0">
          <div fxLayout="row wrap" class="col-gap">
            <div fxFlex.xs="100" fxFlex.sm="100" fxFlex.md="50" fxFlex.lg="50" fxFlex.xl="50">
              <div class="main-bg-inner overlay-section-item overlay-section-overlay relative" style="background-image: url('assets/images/col-men.jpg')">
                <div class="end-left">
                  <div class="overlay-section-content">
                    <h4 class="mb-0 font-bold">Mens Collections</h4>
                    <h4 class=" text-xxl font-normal mb-3">Save Upto 40%</h4>
                    <button mat-raised-button [routerLink]="['/products']">SHOP NOW</button>
                  </div>
                </div>
              </div>
            </div>
            <div fxFlex.xs="100" fxFlex.sm="100" fxFlex.md="50" fxFlex.lg="50" fxFlex.xl="50">
              <div class="main-bg-inner overlay-section-item overlay-section-overlay relative" style="background-image: url('assets/images/col-women.jpg')">
                <div class="end-right">
                  <div class="overlay-section-content">
                    <h4 class="mb-0 font-bold">Women's Collections</h4>
                    <h4 class=" text-xxl font-normal mb-3">Save Upto 30%</h4>
                    <button mat-raised-button [routerLink]="['/products']">SHOP NOW</button>
                  </div>
                </div>
              </div>
            </div>
            <div fxFlex.xs="100" fxFlex.sm="100" fxFlex.md="50" fxFlex.lg="50" fxFlex.xl="50">
              <div class="main-bg-inner overlay-section-item overlay-section-overlay relative" style="background-image: url('assets/images/col-watches.jpg')">
                <div class="end-left">
                  <div class="overlay-section-content">
                    <h4 class="mb-0 font-bold">Watches Collections</h4>
                    <h4 class=" text-xxl font-normal mb-3">Save Upto 50%</h4>
                    <button mat-raised-button [routerLink]="['/products']">SHOP NOW</button>
                  </div>
                </div>
              </div>
            </div>
            <div fxFlex.xs="100" fxFlex.sm="100" fxFlex.md="50" fxFlex.lg="50" fxFlex.xl="50">
              <div class="main-bg-inner overlay-section-item overlay-section-overlay relative" style="background-image: url('assets/images/col-accessories.jpg')">
                <div class="end-right">
                  <div class="overlay-section-content">
                    <h4 class="mb-0 font-bold">Accessories Collections</h4>
                    <h4 class=" text-xxl font-normal mb-3">Save Upto 60%</h4>
                    <button mat-raised-button [routerLink]="['/products']">SHOP NOW</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>