import { Component, OnInit, AfterViewChecked } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ChangeDetectorRef } from '@angular/core';
import * as moment from 'moment-timezone';


import { EmbryoService } from '../../Services/Embryo.service';
import { AdminPanelServiceService } from 'src/app/AdminPanel/Service/AdminPanelService.service';

@Component({
   selector: 'app-homeone',
   templateUrl: './Home.component.html',
   styleUrls: ['./Home.component.scss']
})
export class HomeComponent implements OnInit, AfterViewChecked {

   blogList: any;
   productReviews: any;
   productsArray: any;
   productsSliderData: any;
   newProductsSliderData: any;
   slideConfig = {
      slidesToShow: 4,
      slidesToScroll: 4,
      autoplay: true,
      autoplaySpeed: 2000,
      dots: true,
      responsive: [
         {
            breakpoint: 992,
            settings: {
               arrows: false,
               slidesToShow: 2,
               slidesToScroll: 1
            }
         },
         {
            breakpoint: 768,
            settings: {
               arrows: false,
               slidesToShow: 2,
               slidesToScroll: 2
            }
         },
         {
            breakpoint: 480,
            settings: {
               arrows: false,
               slidesToShow: 1,
               slidesToScroll: 1
            }
         }
      ]
   };

   rtlSlideConfig = {
      slidesToShow: 4,
      slidesToScroll: 4,
      autoplay: true,
      autoplaySpeed: 2000,
      dots: true,
      rtl: true,
      responsive: [
         {
            breakpoint: 992,
            settings: {
               arrows: false,
               slidesToShow: 2,
               slidesToScroll: 1
            }
         },
         {
            breakpoint: 768,
            settings: {
               arrows: false,
               slidesToShow: 2,
               slidesToScroll: 1
            }
         },
         {
            breakpoint: 480,
            settings: {
               arrows: false,
               slidesToShow: 1,
               slidesToScroll: 1
            }
         }
      ]
   };

   partners: any[] = [];
   selectedIndex: number = 0;
   isStoreOpen: boolean = false;

   constructor(
      public embryoService: EmbryoService,
      private cdRef: ChangeDetectorRef,
      public adminService: AdminPanelServiceService) {

      this.getFeaturedProducts();
      this.getBlogList();
      this.getProductRevies();

      this.embryoService.featuredProductsSelectedTab = 0;
      this.embryoService.newArrivalSelectedTab = 0;
   }

   ngOnInit() {
      // Check if the store is open
      this.adminService.isStoreOpen();

      this.adminService.getPartners().subscribe(res => {
         this.partners = res.map((val: any, index: number) => {
            const data = val.payload.val();
            const key = val.payload.key;
            return { key, ...data };
         });

         // Get Store window "status: boolean"  
         this.adminService.isStoreOpenBehaviour.subscribe((status: boolean) => {         
            this.isStoreOpen = status;
            if (status) {
               this.onPartnerTabSelected(0);
            }
         });
      });
   }

   ngAfterViewChecked(): void {
      this.cdRef.detectChanges();
   }

   public getFeaturedProducts() {
      this.embryoService.getProducts().valueChanges().subscribe(res => { this.productsArray = res });
   }

   public getBlogList() {
      this.embryoService.getBlogList().valueChanges().subscribe(res => { this.blogList = res });
   }

   public addToCart(value) {
      this.embryoService.addToCart(value);
   }

   public getProductRevies() {
      this.embryoService.getProductReviews().valueChanges().subscribe(res => { this.productReviews = res });
   }

   public addToWishlist(value) {
      this.embryoService.addToWishlist(value);
   }

   public onFeaturedSelectedTab(tabIndex) {
      this.productsSliderData = null;
      switch (tabIndex) {
         case 0:
            this.productsSliderData = this.productsArray.men;
            break;

         case 1:
            this.productsSliderData = this.productsArray.women;
            break;

         case 2:
            this.productsSliderData = this.productsArray.gadgets;
            break;

         case 3:
            this.productsSliderData = this.productsArray.accessories;
            break;

         default:
            // code...
            break;
      }

      return true;
   }

   public onPartnerTabSelected(tabIndex: number) {      
      
      const partner = this.partners[tabIndex];

      this.adminService
         .getProducts(partner.key, 8)
         .valueChanges()
         .subscribe(res => {
            this.productsSliderData = res; 
         });

      return true;
   }

   public onNewArrivalsSelectedTab(tabIndex) {
      this.newProductsSliderData = null;
      switch (tabIndex) {
         case 0:
            this.newProductsSliderData = this.productsArray.men;
            break;

         case 1:
            this.newProductsSliderData = this.productsArray.women;
            break;

         case 2:
            this.newProductsSliderData = this.productsArray.gadgets;
            break;

         case 3:
            this.newProductsSliderData = this.productsArray.accessories;
            break;

         default:
            // code...
            break;
      }

      return true;
   }
}
