<form [formGroup]="addTermForm" class="popup-card-width add-new-user">
	<div>
		<h2 mat-dialog-title>Add New Term</h2>
	</div>	
	<mat-dialog-content class="mb-4">
		<div>
			<div>
				<mat-form-field class="w-100">
					<input matInput type="text" formControlName="name" class="form-control" placeholder="Name">
					<mat-error *ngIf="addTermForm.controls['name'].touched && !addTermForm.controls['name'].valid">
						<mat-error *ngIf="addTermForm.controls['name'].hasError('required')" class="required alert-error">  
							You must include a Question.
						</mat-error>
		         </mat-error>
				</mat-form-field>
				<mat-form-field class="w-100">
					<input matInput type="text" formControlName="content" class="form-control" placeholder="Content">
					<mat-error *ngIf="addTermForm.controls['content'].touched && !addTermForm.controls['content'].valid">
						<mat-error *ngIf="addTermForm.controls['content'].hasError('required')" class="required alert-error">  
							You must include a Answer.
						</mat-error>
		         </mat-error>
				</mat-form-field>
			</div>
      </div>
	</mat-dialog-content>
	<mat-dialog-actions class="m-0">
		<button type="button" mat-raised-button (click)="dialogRef.close()" color="primary">CLOSE</button>
		<button (click)="onFormSubmit()" type="submit" [disabled]="!addTermForm.valid" mat-raised-button color="warn">SUBMIT</button>
	</mat-dialog-actions>
</form>
<!-- add-new-user -->