import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AdminPanelServiceService } from 'src/app/AdminPanel/Service/AdminPanelService.service';

@Component({
  selector: 'ms-add-new-client',
  templateUrl: './AdminAddUser.component.html',
  styleUrls: ['./AdminAddUser.component.scss']
})
export class AdminAddUserComponent implements OnInit {

	addNewUserForm    : FormGroup;
	emailPattern 		: string = "^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$";

	constructor( private formBuilder : FormBuilder,
					 public dialogRef    : MatDialogRef<AdminAddUserComponent>, private adminService:AdminPanelServiceService,
					 @Inject(MAT_DIALOG_DATA) public data: any
					 ) { }

	ngOnInit() {
	
		this.addNewUserForm = this.formBuilder.group({
			firstName 	     : [this.data ? this.data.firstName : '',[Validators.required]],
			lastName: [this.data ? this.data.lastName :'',[Validators.required]],
			email 		 : [this.data ? this.data.email :'',[Validators.required,Validators.pattern(this.emailPattern)]],
			password: [this.data ? this.data.password : '', this.data ? [] : [Validators.required, Validators.minLength(6)]],
		})
		
	}

	// onFormSubmit method is submit a add new user form.
	onFormSubmit(){
		console.log("ON SUBMIT",this.data)
		if(!this.data) {
			this.adminService.createUser({...this.addNewUserForm.value,enabled:'Yes'});
		} else {
			this.adminService.updateUser(this.data.key,{...this.addNewUserForm.value,enabled:this.data.enabled});
			
		}
		
		this.dialogRef.close();
	}
}