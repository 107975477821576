import { Component, Inject, OnInit } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-order-history',
  templateUrl: './order-history.component.html',
  styleUrls: ['./order-history.component.scss']
})
export class OrderHistoryComponent implements OnInit {

  displayedColumns: string[] = ['position', 'orderid', 'name', 'price', 'status','action'];
  public dataSource = null;

  constructor(@Inject(MAT_DIALOG_DATA) public data: string, private db: AngularFireDatabase) { }

  ngOnInit(): void {
    this.db.list('orders', ref => ref.orderByChild('userId').equalTo(this.data)).snapshotChanges().pipe(
      map(orders => orders.map(order =>{
         const data = order.payload.val() as any;
         return {
            transcationId : order.key,
            ...data
         }
      }))
   ).subscribe(orders =>{
      let order_info = orders.map((order, index) =>{
         return {
            position: index+1,
            orderid: order.transcationId,
            name: Object.values(order.items)[0]['name'],
            price: Object.values(order.items)[0]['amount'],
            status: order.status
         }
      });
      this.dataSource = order_info;
   })  
  }

}
