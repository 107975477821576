import { Component, OnInit, Input } from '@angular/core';
import { AdminPanelServiceService } from 'src/app/AdminPanel/Service/AdminPanelService.service';
import * as moment from 'moment-timezone';
import { DateTime } from "luxon";

@Component({
	selector: 'embryo-DealOfTheDay',
	templateUrl: './DealOfTheDay.component.html',
	styleUrls: ['./DealOfTheDay.component.scss']
})
export class DealOfTheDayComponent implements OnInit {

	@Input() singleProduct: any;

	@Input() currency: string;

	counterDateTime;
	value;

	constructor(
		public adminService: AdminPanelServiceService
	) { }

	async ngOnInit() {
		const payload = await this.adminService.getNextWindow();
		this.adminService.getWindowCountDown().subscribe(resp => {
			// console.log("VALUE",resp.payload.key,resp.payload.val());
		
			this.value = resp.payload.val();
			if (payload) {
				const window = payload.payload.val();
				const startMoment = DateTime
					.fromSeconds(window.startDate)
					.setZone('utc')
					.setZone('America/Chicago')
					.set({
						hour: window.startTime.split(':')[0],
						minute: window.startTime.split(':')[1]
					});	
				this.counterDateTime = startMoment;
			}
		});
		
	}

	/**
	 * getOfferImagePath is used to change the image path on click event.
	 */
	public getOfferImagePath(imgPath: any, index: number) {
		document.querySelector('.border-active').classList.remove('border-active');
		this.singleProduct.image = imgPath;
		document.getElementById(index + '_img').className += " border-active";
	}

}
