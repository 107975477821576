<mat-toolbar color="primary" class="header-v1">
  <mat-toolbar-row class="header-v1-top">
    <div class="container">
      <div fxLayout="row wrap" fxLayout="center center">
        <div class="logo-site" fxFlex.xs="40" fxFlex.sm="40" fxFlex.md="50" fxFlex.lg="33%" fxFlex.xl="33%">
          <embryo-AppLogo></embryo-AppLogo>
        </div>
        <div fxFlex.xs="60" fxFlex.sm="60" fxFlex.md="50" fxFlex.lg="66%" fxFlex.xl="66%">
          <span fxFlex></span>
          <button *ngIf="!(auth.isLoggedIn$ | async)" mat-raised-button color="accent"
            [routerLink]="['/session/signin']"><i
              class="material-icons mr-1">power_settings_new</i><span>Login</span></button>
          <div *ngIf="auth.isLoggedIn$ | async" class="list-flex user-tool">
            <embryo-HeaderCart [currency]="embryoService?.currency"
              [cartProducts]="embryoService?.localStorageCartProducts" [count]="embryoService?.navbarCartCount"
              (removeProductData)="openConfirmationPopup($event)"></embryo-HeaderCart>

            <embryo-WishList [currency]="embryoService?.currency"
              [wishListProducts]="embryoService?.localStorageWishlist" [count]="embryoService?.navbarWishlistProdCount"
              (removeWishListData)="openWishlistConfirmationPopup($event)"
              (addAllWishlistToCart)="addAllWishlistToCart($event)" (addToCart)="addToCart($event)"></embryo-WishList>

            <embryo-HeaderUserProfileDropdown></embryo-HeaderUserProfileDropdown>
          </div>
        </div>
      </div>
    </div>
  </mat-toolbar-row>
  <mat-toolbar-row class="header-v1-bottom main-menu-wrap">
    <div class="container">
      <div class="header-v1-bottom-inner">
        <embryo-Menu></embryo-Menu>
        <div class="search-box">
          <button type="button" mat-fab (click)="toggleSearch()">
            <i class="material-icons notranslate">
              search
            </i>
          </button>
        </div>
        <div class="search-form">
          <form>
            <input type="text" placeholder="Search and hit enter">
            <button type="button" mat-fab class="close-btn" (click)="toggleSearch()">
              <i class="material-icons">
                close
              </i>
            </button>
          </form>
        </div>
        <button class="responsive-toggle" mat-icon-button (click)="toggleSidebar()">
          <i class="material-icons">menu</i>
        </button>
      </div>
    </div>
  </mat-toolbar-row>
  <mat-toolbar-row class="responsive-bar relative">
    <div class="container">
      <div fxLayoutAlign="space-between">

        <button class="responsive-toggle" mat-icon-button (click)="toggleSidebar()">
          <i class="material-icons">menu</i>
        </button>
        <div>
          <div class="search-box-bar">
            <button type="button" mat-mini-fab (click)="toggleSearch()">
              <i class="material-icons notranslate">
                search
              </i>
            </button>
          </div>
          <div class="search-form">
            <form>
              <input type="text" placeholder="Search and hit enter">
              <button type="button" mat-fab class="close-btn" (click)="toggleSearch()">
                <i class="material-icons">
                  close
                </i>
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </mat-toolbar-row>
</mat-toolbar>
<embryo-FixedHeader></embryo-FixedHeader>