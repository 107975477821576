import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "src/app/Services/auth.service";

@Component({
  selector: "embryo-HeaderUserProfileDropdown",
  templateUrl: "./HeaderUserProfileDropdown.component.html",
  styleUrls: ["./HeaderUserProfileDropdown.component.scss"],
})
export class HeaderUserProfileDropdownComponent implements OnInit {
  letters;
  userInfo;
  constructor(private router: Router, private authService: AuthService) {}

  ngOnInit() {
    this.authService.userValue.subscribe((user:any)=>{
      if(user){
        this.userInfo = user;
        // const [firstName,lastName] = user.split(' ');
        this.letters = `${user.firstName[0]}${user.lastName[0]}`
      }
    })
  }

  logOut() {
    this.authService.signOut();
    this.router.navigate(["/session/signin"]);
  }
}
