import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { LoadingBarService } from "@ngx-loading-bar/core";
import { ToastaService } from "ngx-toasta";
import { AuthService } from "src/app/Services/auth.service";

@Component({
  selector: "embryo-SignIn",
  templateUrl: "./CommonSignIn.component.html",
  styleUrls: ["./CommonSignIn.component.scss"],
})
export class CommonSignInComponent implements OnInit {
  loginForm = new FormGroup({
    email: new FormControl("", [Validators.required, Validators.email]),
    password: new FormControl("", Validators.required),
  });

  requiredFieldMessage = "This field is required";

  get email() {
    return this.loginForm.get("email");
  }

  get password() {
    return this.loginForm.get("password");
  }

  constructor(
    private toastService: ToastaService,
    private authService: AuthService,
    private router: Router,
    private loadingService: LoadingBarService
  ) {}

  ngOnInit() {}

  signIn() {
    if (!this.loginForm.valid) {
      return;
    }

    this.authService.signInWithEmail(this.loginForm.value).subscribe(
      () => {
        this.toastService.success({
          title: "Success",
          msg: "Login was successful!",
          showClose: true,
          timeout: 3000,
        });
        this.router.navigate([""]);
      },
      ({ message }) => {
        this.toastService.error({
          title: "Sign In failed",
          msg: message,
          showClose: true,
          timeout: 3000,
        });
      }
    );
  }
}
