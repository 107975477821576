<button mat-mini-fab class="log-in-user" [matMenuTriggerFor]="loginuser">
    {{letters}}
    <div class="material-icons iconStyle" *ngIf="userInfo?.admin === 'Yes'">
        <img src="assets/icons/shield-alt-duotone.svg" class="iconStyle-header" />
        </div>
</button>
<mat-menu #loginuser="matMenu">
  <button mat-menu-item fxLayoutAlign="start center" [routerLink]="['/account/profile']"><i
      class="material-icons mr-1">account_circle</i> <span>Profile</span>
  </button>
  <button mat-menu-item (click)="logOut()"><i
      class="material-icons mr-1">power_settings_new</i><span>Logout</span></button>
</mat-menu>