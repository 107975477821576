import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes} from '@angular/router';
import { MainAdminPanelComponent } from './Main/Main.component';
import {AdminAuthGuardService} from '../AdminPanel/Service/AdminAuthGuard.service';

export const AdminPanelRoutes : Routes = [
   {
      path : 'admin-panel',
      redirectTo: 'admin-panel/reports',
      pathMatch: 'full',
   }, 
   {
      path : "admin-panel",
      component : MainAdminPanelComponent,
      canActivate: [AdminAuthGuardService],
      children: [ 
         {
            path: 'reports',loadChildren: ()=>
            import('./Reports/Reports.module').then (m => m.ReportsModule),
            canActivate: [AdminAuthGuardService],
         },
         {
            path: 'invoices',loadChildren: ()=>
            import('./Invoices/Invoices.module').then (m => m.InvoicesModule),
            canActivate: [AdminAuthGuardService],
         },
         {
            path: '',loadChildren: ()=>
            import('./Products/Products.module').then(m => m.ProductsModule),
            canActivate: [AdminAuthGuardService],
         },
         {
            path: '',loadChildren: ()=>
            import('./Pages/Pages.module').then(m => m.PagesModule),
            canActivate: [AdminAuthGuardService],
         },
         {
            path: 'account',loadChildren: ()=>
            import('./AdminAccount/AdminAccount.module').then (m => m.AdminAccountModule),
            canActivate: [AdminAuthGuardService],
         },{
            path: 'usersList',loadChildren: ()=>
            import('../AdminPanel/Users/users.module').then (m => m.UsersListModule),
            canActivate: [AdminAuthGuardService],
         }
      ]
   }
]