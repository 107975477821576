import {Injectable} from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase } from '@angular/fire/database';
import {CanActivate, Router} from '@angular/router';
import { AuthService } from '../../Services/auth.service';
import { map } from "rxjs/operators";

@Injectable({
    providedIn:'root'
})

export class AdminAuthGuardService implements CanActivate {

    constructor(private route: Router, private authService: AuthService) {}

    async canActivate(): Promise < boolean > {
        //   Checks whether current user is Admin, resolves to True if
        return new Promise((resolve, reject) => {
            this.authService.checkIsUserAdmin.subscribe(resp => {
                if (resp === 'Yes') {
                    resolve(true);
                } else if (resp === 'No') {
                    // console.log("logged in",resp,this.route.url);
                    this.route.navigate([this.route.url]);
                    resolve(false);
                } 
                else if (resp === "No User") {
                    // console.log("No user logged in",this.route.url);
                    this.route.navigate([this.route.url+''])
                }
            })
        })
    }
}