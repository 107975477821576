<footer>
  <div class="footer-v1 section-gap">
    <div class="container">
      <div class="footer-top-v1">
        <div fxLayout="row wrap" class="col-gap">
          <div fxFlex.xs="100" fxFlex.sm="100" fxFlex.md="40" fxFlex.lg="40" fxFlex.xl="40">
            <h6>About Company</h6>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Nam quo nesciunt pariatur laboriosam
              accusantium? Doloremque fugit
              unde explicabo consequuntur aliquid laudantium id voluptatum? Saepe earum, suscipit ex quos eius natus.
            </p>
          </div>
          
          <div fxFlex.xs="100" fxFlex.sm="25" fxFlex.md="15" fxFlex.lg="15" fxFlex.xl="15" *ngFor="let menu of menuItems.getFooterOneMenu()">
            <div>
              <p>{{menu.name | translate}}</p>
              <mat-list class="list-sm" *ngFor="let subMenu of menu.children">
                <mat-list-item *ngIf="subMenu.type == 'link'"><a [routerLink]="['/'+subMenu.state]" routerLinkActive="active-link"> {{subMenu.name | translate}}</a></mat-list-item>
                <mat-list-item *ngIf="subMenu.type == 'social_link'"><a href="{{subMenu.state}}" target="_blank"> {{subMenu.name | translate}}</a></mat-list-item>
              </mat-list>
            </div>
          </div>
        </div>
      </div>
      <hr>
      <div class="footer-bottom-v1">
        <div fxLayout='row wrap' class="col-gap">
          <div fxFlex.xs="100" fxFlex.sm="100" fxFlex.md="50" fxFlex.lg="50" fxFlex.xl="50">
            <img src="assets/images/cards.png" width="218" height="18" alt="Credit Cards">
          </div>
          <div fxFlex.xs="100" fxFlex.sm="100" fxFlex.md="50" fxFlex.lg="50" fxFlex.xl="50" class="text-right">
            &copy; All Rights Reversed | Made With Love by IRON NETWORK for better Web
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>
