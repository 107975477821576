<form [formGroup]="addMissionForm" class="popup-card-width add-new-user">
	<div>
		<h2 mat-dialog-title>Add New Mission</h2>
	</div>	
	<mat-dialog-content class="mb-4">
		<div>
			<div>
				<mat-form-field class="w-100">
					<input matInput type="text" formControlName="heading" class="form-control" placeholder="Heading">
					<mat-error *ngIf="addMissionForm.controls['heading'].touched && !addMissionForm.controls['heading'].valid">
						<mat-error *ngIf="addMissionForm.controls['heading'].hasError('required')" class="required alert-error">  
							You must include a Heading.
						</mat-error>
		         </mat-error>
				</mat-form-field>
				<mat-form-field class="w-100">
					 <mat-label>Content</mat-label>
            <textarea matInput
            formControlName="content"
            cols="35" rows="15"></textarea>
					<mat-error *ngIf="addMissionForm.controls['content'].touched && !addMissionForm.controls['content'].valid">
						<mat-error *ngIf="addMissionForm.controls['content'].hasError('required')" class="required alert-error">  
							You must include a Content.
						</mat-error>
		         </mat-error>
				</mat-form-field>
				<mat-form-field class="w-100">
					<input matInput type="text" formControlName="sub_heading" class="form-control" placeholder="Sub Heading">
					<mat-error *ngIf="addMissionForm.controls['sub_heading'].touched && !addMissionForm.controls['sub_heading'].valid">
						<mat-error *ngIf="addMissionForm.controls['sub_heading'].hasError('required')" class="required alert-error">  
							You must include a Sub Heading.
						</mat-error>
		         </mat-error>
				</mat-form-field>
				<mat-form-field class="w-100">
					<input matInput type="text" formControlName="image" class="form-control" placeholder="Image">
					<mat-error *ngIf="addMissionForm.controls['image'].touched && !addMissionForm.controls['image'].valid">
						<mat-error *ngIf="addMissionForm.controls['image'].hasError('required')" class="required alert-error">  
							You must include a Image path.
						</mat-error>
		         </mat-error>
				</mat-form-field>
			</div>
      </div>
	</mat-dialog-content>
	<mat-dialog-actions class="m-0">
		<button type="button" mat-raised-button (click)="dialogRef.close()" color="primary">CLOSE</button>
		<button (click)="onFormSubmit()" type="submit" [disabled]="!addMissionForm.valid" mat-raised-button color="warn">SUBMIT</button>
	</mat-dialog-actions>
</form>
<!-- add-new-user -->