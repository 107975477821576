<form [formGroup]="loginForm" (ngSubmit)="signIn()">
  <div class="mb-4">
    <mat-form-field class="w-100">
      <input matInput type="email" placeholder="Email" formControlName="email">
      <mat-error *ngIf="email.errors?.required">{{requiredFieldMessage}}</mat-error>
      <mat-error *ngIf="email.errors?.email">Please enter a valid email address</mat-error>
    </mat-form-field>
  </div>
  <div class="mb-4">
    <mat-form-field class="w-100">
      <input matInput type="password" placeholder="Password" formControlName="password">
      <mat-error *ngIf="password.errors?.required">{{requiredFieldMessage}}</mat-error>
    </mat-form-field>
  </div>
  <div class="mb-3">
    <mat-checkbox>Remember Me</mat-checkbox>
    <span fxFlex></span> <a [routerLink]="['/session/forgot-password']">Forgot Password?</a>
  </div>
  <button color="accent" type="submit" class="button-lg mb-3" mat-raised-button>Sign In</button>
  <p>Don't have an account? <a [routerLink]="['/session/signup']">Click here to create one</a></p>
</form>