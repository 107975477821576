<form [formGroup]="addNewUserForm" class="popup-card-width add-new-user">
	<div>
		<h2 mat-dialog-title *ngIf="!data">Add New User</h2>
		<h2 mat-dialog-title *ngIf="data">Edit User</h2>
	</div>	
	<mat-dialog-content class="mb-4">
		<div>
			<div>
				<mat-form-field class="w-100">
					<input matInput type="text" formControlName="firstName" class="form-control" placeholder="First Name">
					<mat-error *ngIf="addNewUserForm.controls['firstName'].touched && !addNewUserForm.controls['firstName'].valid">
						<mat-error *ngIf="addNewUserForm.controls['firstName'].hasError('required')" class="required alert-error">  
							You must include a First Name.
						</mat-error>
		         </mat-error>
				</mat-form-field>
				<mat-form-field class="w-100">
					<input matInput type="text" formControlName="lastName" class="form-control" placeholder="Last Name">
					<mat-error *ngIf="addNewUserForm.controls['lastName'].touched && !addNewUserForm.controls['lastName'].valid">
						<mat-error *ngIf="addNewUserForm.controls['lastName'].hasError('required')" class="required alert-error">  
							You must include a Last Name.
						</mat-error>
		         </mat-error>
				</mat-form-field>
            <mat-form-field class="w-100">
               <input matInput type="text" formControlName="email" class="form-control" placeholder="Email">
               <mat-error *ngIf="addNewUserForm.controls['email'].touched && !addNewUserForm.controls['email'].valid">
                  <mat-error *ngIf="addNewUserForm.controls['email'].hasError('required')" class="required alert-error">  
                     You must include a E-mail Address.
                  </mat-error>  
                  <mat-error *ngIf="addNewUserForm.controls['email'].hasError('pattern')">
                     E-mail must be valid
                  </mat-error>
               </mat-error>
            </mat-form-field> 
			<mat-form-field class="w-100" *ngIf="!data">
				<input matInput type="text" formControlName="password" class="form-control" placeholder="Password">
				<mat-error *ngIf="addNewUserForm.controls['password'].touched && !addNewUserForm.controls['password'].valid">
				   <mat-error *ngIf="addNewUserForm.controls['password'].hasError('required')" class="required alert-error">  
					  You must enter a password.
				   </mat-error>  
				   <mat-error *ngIf="addNewUserForm.controls['password'].hasError('minLength')">
					  Password should have at least 6 characters
				   </mat-error>
				</mat-error>
			 </mat-form-field> 
			 <!-- <mat-form-field class="w-100">
				<input matInput type="text" formControlName="enabled" class="form-control" placeholder="Enable" readonly>
			</mat-form-field> -->
			</div>
      </div>
	</mat-dialog-content>
	<mat-dialog-actions class="m-0">
		<button type="button" mat-raised-button (click)="dialogRef.close()" color="primary">CLOSE</button>
		<button (click)="onFormSubmit()" type="submit" [disabled]="!addNewUserForm.valid" mat-raised-button color="warn">SUBMIT</button>
	</mat-dialog-actions>
</form>
<!-- add-new-user -->