<a mat-list-item (click)="onItemSelected(item)" [ngClass]="{'active': item.state ? router.isActive(item.state, true): false, 'expanded': expanded}">
	<mat-icon>{{item.icon}}</mat-icon>
	{{item.name | translate}}
	<span fxFlex *ngIf="item.children && item.children.length">
		<span fxFlex></span>
		<mat-icon [@indicatorRotate]="expanded ? 'expanded': 'collapsed'">
		expand_more
		</mat-icon>
	</span>
</a>
<div *ngIf="expanded" class="responsive-menu-side">
	<embryo-SidebarMenu *ngFor="let child of item.children" [item]="child" [depth]="depth+1">
	</embryo-SidebarMenu>
</div>

