<div class="download-app py-2">
   <div class="container text-center">
      <div fxLayout="row wrap" class="col-gap">
         <div fxFlex.xs="100" fxFlex.sm="100" fxFlex.md="50" fxFlex.lg="50" fxFlex.xl="50" class="py-4 download-app-list">
         <a href="javascript:void(0);">   <h4 class="mb-0 font-bold"><img class="mr-3" src="assets/images/apple.png" alt="16 x 16" width="22" height="22"> Download iOS App </h4></a>
         </div>

         <div fxFlex.xs="100" fxFlex.sm="100" fxFlex.md="50" fxFlex.lg="50" fxFlex.xl="50" class="py-4 download-app-list">
           <a href="javascript:void(0);"> <h4 class="mb-0 font-bold"><img class="mr-3" src="assets/images/android-logo.png" alt="16 x 16" width="22" height="22"> Download Android App </h4></a>
         </div>
      </div>
   </div>
</div>
