import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PageService } from '../../../Pages/Services/pages.service';

@Component({
  selector: 'ms-add-new-client',
  templateUrl: './AddMission.component.html',
  styleUrls: ['./AddMission.component.scss']
})
export class AddMissionComponent implements OnInit {

	addMissionForm    : FormGroup;
    alVals:any;
	// pattern1 = /<\/?[a-z][\s\S]*>/i; // regex to check HTML closing tag
	constructor( private formBuilder : FormBuilder,
					 public dialogRef    : MatDialogRef<AddMissionComponent>,
					 public pageService : PageService,
					 @Inject(MAT_DIALOG_DATA) public data: any 
					 ) { }

	ngOnInit() {
		this.pageService.getMission().snapshotChanges().subscribe(resp => this.saveAll(resp));
		console.log("PAE SER",this.data)
		let lastId = this.data && this.data.length > 0 ? +this.data[this.data.length-1].id : 0;
		this.addMissionForm = this.formBuilder.group({
			id: [(lastId+1).toString(), [Validators.required]],
			heading 	     : [ '',[Validators.required]],
			content: ['',[Validators.required]],
			sub_heading:['',[Validators.required]],
			image: ['',[Validators.required]],
		});
		this.alVals = this.data;
		// console.log("INIT",this.data);
		
	}

	saveAll(resp) {
		const missions = resp.map(a => {
			const data = a.payload.val();
			const key = a.payload.key;
			// console.log("MAP",key,data)
			return {key,...data};           // or {key, ...data} in case data is Obj
		  }).filter(Boolean)[0];
		  // console.log("Vals",faqs);
		  this.data = missions;
	}

	// onFormSubmit method is submit a add new user form.
	onFormSubmit(){
		let updatedValues = [...this.alVals.map(v => ({
			id:v.id,
			heading:v.heading,
			content:v.content,
			sub_heading:v.sub_heading,
			image:v.image
		})),this.addMissionForm.value];
		let key = this.data && this.data.length > 0 ? this.data.length : 0;
		// console.log("ON SUBMIT",key,this.addMissionForm.value);
		this.pageService.addMission(this.addMissionForm.value);
		this.dialogRef.close();
	}
}