<mat-card class="product-card" *ngIf="product">
   <a class="p-link" [routerLink]="['/products', product?.type, product?.id]">
     <img width="626" height="800" mat-card-image [src]="product?.image ? product?.image : product?.images[0]" alt="Photo">
   </a>
   <div class="wishlist-icon {{index}}-wishlist" id="{{type}}-{{index}}-wishlist">
      <a (click)="productAddToWishlist(product, type+'-'+index+'-wishlist')"><i  class="material-icons"> favorite </i></a>
   </div>
  <mat-card-content class="product-content relative">
     <h5><a [routerLink]="['/products', product?.type, product?.id]" class="title-link">{{product?.name}}</a> </h5>
     <div>
        <div fxFlex fxLayoutAlign="space-between">
           <h6 class="accent-color">{{product?.price | currency: currency}}</h6>
           <div class="rating-star">
              <embryo-Rating [rate]="product.rating"></embryo-Rating>
           </div>
        </div>
     </div>
     <div class="m-icon" *ngIf="checkCartAlready(product)">
      <embryo-AddToCardButton [product]="product" (addToCart)="addToCartProduct($event)"></embryo-AddToCardButton>
     </div>
     <div class="m-icon" *ngIf="!checkCartAlready(product)">
         <button mat-fab [routerLink]="['/products', product?.type, product?.id]"><i  class="material-icons"> remove_red_eye </i></button>
     </div>
  </mat-card-content>
</mat-card>


