<div [dir]="isRtl" (scroll)="onScrollEvent($event)">
   <mat-sidenav-container>
      <mat-sidenav #sidenav [(opened)]="embryoService.sidenavOpen" class="sidebar-area" mode="over">
         <mat-nav-list>
            <div class="close-btn-sidebar">
               <a href="javascript:void(0)" (click)="embryoService.sidenavOpen = false">
                  <i class="material-icons">
                     close
                  </i>
               </a>
            </div>
            <embryo-SidebarMenu *ngFor="let item of menuItems.getMainMenu()" [item]="item"></embryo-SidebarMenu>
         </mat-nav-list>
      </mat-sidenav>
      <mat-sidenav class="em-sidebar" #paymentSidenav [(opened)]="embryoService.paymentSidenavOpen" position="end"
         mode="over">
         <embryo-PaymentDetailSideBar></embryo-PaymentDetailSideBar>
      </mat-sidenav>
      <Header></Header>
      <div class="main">
         <router-outlet (activate)="onActivate($event)"></router-outlet>
      </div>
      <Footer></Footer>
      <ngx-toasta></ngx-toasta>
      <ngx-loading-bar></ngx-loading-bar>
   </mat-sidenav-container>
</div>