import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFireDatabase } from "@angular/fire/database";
import { ToastaService } from "ngx-toasta";
import { async, BehaviorSubject, forkJoin, from, Observable, Subject } from "rxjs";
import { concatMap, map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  public readonly isLoggedIn$ = this.auth.user.pipe(map((user) => !!user));
  public user_info: BehaviorSubject<any> = new BehaviorSubject(null);
  public isUserAdmin: Subject<string> = new Subject();
  public userValue:Subject<object> = new Subject();

  constructor(private auth: AngularFireAuth, private db: AngularFireDatabase,private toastService: ToastaService) {
    this.auth.user.subscribe((user)=>{
      this.user_info.next(user);
    });
  }

  public get checkIsUserAdmin(): Observable < string > {
    this.auth.user.subscribe(userDetails => {
      if(userDetails) {
        this.db
        .object(`users/${userDetails.uid}`).snapshotChanges().subscribe((resp: any) => {
          const user = resp.payload.val();
          this.userValue.next(user);
          if (user.admin && user.admin === 'Yes') {
            this.isUserAdmin.next('Yes');
          } else {
            this.isUserAdmin.next('No');
          }
        })
      } else {
        this.isUserAdmin.next('No User');
      }
     
    })
    return this.isUserAdmin.asObservable();
  }

  signUpUser({ email, password, firstName, lastName }): Observable<any> {
    return from(this.auth.createUserWithEmailAndPassword(email, password)).pipe(
      concatMap(({ user }) => {
        let displayname = `${firstName} ${lastName}`;
        user.updateProfile({
          displayName: displayname,
        });
        this.user_info.next(user);
        this.db
        .object(`users/${user.uid}`)
        .update({ email, firstName, lastName });
        return this.auth.currentUser;
      })
    );
  }

  signInWithEmail({ email, password }): Observable<any> {
    return from(this.auth.signInWithEmailAndPassword(email, password)).pipe(
      concatMap(({ user }) => {
        console.log(user);
        this.user_info.next(user);
        this.db
        .object(`users/${user.uid}`).snapshotChanges().subscribe((resp:any) => {      
          const user = resp.payload.val();
           this.userValue.next(user);
          if(user.admin && user.admin === 'Yes') {
            this.isUserAdmin.next('Yes');
          } else {
            this.isUserAdmin.next('No');
          }
        })
        return this.auth.currentUser;
      })
    );
  }

  async signOut() {
   await this.auth.signOut().then(resp => {
      // console.log("Logged Out")
      this.isUserAdmin.next('No');
      this.userValue.next(null);
      this.user_info.next(null);
      this.toastService.success({
        title: "Success",
        msg: "User Logged Out!",
        showClose: true,
        timeout: 3000,
      });
    }).catch(() => {
      this.toastService.error({
        title: "Error",
        msg: "Error while trying to logout!",
        showClose: true,
        timeout: 3000,
      });
    });
    
  }

  getUserDetails(): Observable<any> {
    this.auth.user.subscribe(userDetails => {
      if(userDetails) {
        this.db
        .object(`users/${userDetails.uid}`).snapshotChanges().subscribe((resp: any) => {
          const user = resp.payload.val();
          this.userValue.next(user);
      
        })
      } else {
        this.userValue.next(null);
      }
     
    })
    return this.userValue.asObservable();
}

updateUserDetails(formValue) {
  return new Promise((resolve, reject) => {

     this.auth.user.subscribe((userDetails) => {
      if(userDetails) {
        const userObj = this.db
        .object(`users/${userDetails.uid}`);
        userObj.snapshotChanges().subscribe(async (resp: any) => {
          // const key = resp.payload.key;
          const user = resp.payload.val();
          const userValue = {
            firstName : formValue.firstName ? formValue.firstName : '',
            lastName : formValue.lastName ? formValue.lastName : '',
            email: user.email,
            phone: formValue.phone ? formValue.phone : '',
            // date: formValue.date ? new Date(formValue.date).toISOString() : '',
            // gender: formValue.gender ? formValue.gender : '',
            // location: formValue.location ? formValue.location : '',
            admin: user.admin ? 'Yes' : 'No',
            customerId: user.customerId,
            enabled: user.enabled ? 'Yes' : 'No',
            order_history: user.order_history,
          }
          
         await userObj.set(userValue).then(() => resolve(true)).catch(() =>  resolve(false));
        })
      }
    })
})

}
}
