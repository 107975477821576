import {
	Injectable
} from '@angular/core';
import {
	HttpClient
} from '@angular/common/http';
// import { Observable } from 'rxjs/Rx';
// import * as screenfull from 'screenfull';
import {
	AngularFireDatabase,
	AngularFireObject
} from "@angular/fire/database";
import {
	AngularFirestore
} from '@angular/fire/firestore';
// import { AuthService } from 'src/app/Services/auth.service';
// import { map } from 'rxjs-compat/operator/map';
import {
	ToastaService
} from 'ngx-toasta';
import {
	AngularFireAuth
} from '@angular/fire/auth';
import {
	MatDialog,
	MatDialogRef
} from '@angular/material/dialog';
import {
	AddFAQComponent
} from '../../Widget/PopUp/AddFaq/AddFaq.component';
import {
	DeleteUserDialogComponent
} from '../../Widget/PopUp/DeleteUserDialog/DeleteDialog.component';
import { AddTermComponent } from '../../Widget/PopUp/AddTerms/AddTerm.component';
import { AddMissionComponent } from '../../Widget/PopUp/AddMission/AddMission.component';
import { AddPrivacyPolicyComponent } from '../../Widget/PopUp/AddPrivacyPolicy/AddPrivacyPolicy.component';
// import { first } from 'rxjs-compat/operator/first';

@Injectable({
	providedIn: 'root'
})

export class PageService {

	constructor(private http: HttpClient, private dialog: MatDialog,
		private db: AngularFireDatabase, private angularFireStore: AngularFirestore, private authServ: AngularFireAuth, private toastService: ToastaService) {}


	public getContact() {
		return this.db.list('contact');
	}

	public updateContact(contactDetails: any) {
		const keys = Object.keys(contactDetails);
		let allUpdates = [];
		keys.forEach(key => {
			const contact = this.db.list('contact');
			allUpdates.push(contact.set(key, contactDetails[key]));
		});
		const updateAll = Promise.all(allUpdates);
		updateAll.then(resp => {
			console.log("Updated", resp);
			this.toastService.success({
				title: "Success",
				msg: "details updated successfully!",
				showClose: true,
				timeout: 3000,
			});
		}).catch(err => {
			console.log("Error", err);
			this.toastService.error({
				title: "Error",
				msg: err.message,
				showClose: true,
				timeout: 3000,
			});
		})
	}

	public getAboutUs() {
		return this.db.object("about_info");
	}

	public updateAboutUs(key: any, aboutInfoData: any) {
		let aboutInfo = this.db.object("about_info");
		aboutInfo.set(aboutInfoData).then(resp => {
			this.toastService.success({
				title: "Success",
				msg: "details updated successfully!",
				showClose: true,
				timeout: 3000,
			});
		}).catch(err => {
			console.log(err);
			this.toastService.error({
				title: "Error",
				msg: err.message,
				showClose: true,
				timeout: 3000,
			});
		});
	}

	public getTeam() {
		return this.db.list('team');
	}
	public getTerms() {
	// 	let terms = this.db.list('term_condition');
	// terms.remove('term_condition');
		return this.db.list('term_condition');
	}


	public getFaqs() {
		// let faqsDB1 = this.db.list('faq');
		// faqsDB1.set('faqs',[{ans:'aa',ques:'bb'}])
		return this.db.list('faq');
	}

	public addFaq(key, faqs: any) {
		let faqsDB = this.db.list('faq');
		// let faqsDB1 = this.db.list('faq/faqs');
		// console.log("New Value",faqs)
		faqsDB.set('faqs', faqs).then(resp => {
			this.toastService.success({
				title: "Success",
				msg: "FAQ Added!",
				showClose: true,
				timeout: 3000,
			})
		}).catch(() => this.toastService.error({
			title: "Error",
			msg: 'Error while adding FAQ',
			showClose: true,
			timeout: 3000,
		}));
	}
	public updateFaq(key, faq1: any) {
		let faqsDB = this.db.list('faq');
		let faqsDB1 = this.db.list('faq/faqs');
		faqsDB1.set(key + '', faq1).then(() => this.toastService.success({
			title: "Success",
			msg: "FAQ updated!",
			showClose: true,
			timeout: 3000,
		})).catch((err) => this.toastService.error({
			title: "Error",
			msg: 'Error while updating!',
			showClose: true,
			timeout: 3000,
		}));
		return faqsDB;
	}

	deleteFAQ(data: string, data1: any,data2:any) {
		let dialogRef: MatDialogRef < DeleteUserDialogComponent > ;
		dialogRef = this.dialog.open(DeleteUserDialogComponent);
		dialogRef.componentInstance.data = data;
		dialogRef.componentInstance.data1 = data1;
		dialogRef.componentInstance.allValues = data2;
		console.log("On Add",data,data1,data2)
		return dialogRef.afterClosed();
	}

	public removeFaq(key,values) {
		let faqs = this.db.list('faq');
		// const allFaqS = this.db.list('faq/faqs');
		let allFaq = [...values];
		allFaq.splice(key,1);
		faqs.set('faqs',allFaq).then(() => this.toastService.success({
			title: "Success",
			msg: "details deleted successfully!",
			showClose: true,
			timeout: 3000,
		})).catch((err) => this.toastService.error({
			title: "Error",
			msg: 'Error while deleting',
			showClose: true,
			timeout: 3000,
		}));
		return faqs;
	}

	addFaqDialog(faqs: any) {
		let dialogRef: MatDialogRef < AddFAQComponent > ;
		dialogRef = this.dialog.open(AddFAQComponent);
		dialogRef.componentInstance.data = faqs;
		return dialogRef.afterClosed();
	}

	addTermDialogue(terms: any) {
		let dialogRef: MatDialogRef < AddTermComponent > ;
		dialogRef = this.dialog.open(AddTermComponent);
		dialogRef.componentInstance.data = terms;
		return dialogRef.afterClosed();
	}

	 addTerm(key,term: any) {
		let termDb = this.db.list('term_condition');
		termDb.set(key,term).then(resp => {
			this.toastService.success({
				title: "Success",
				msg: "Term Added!",
				showClose: true,
				timeout: 3000,
			})
		}).catch(() => this.toastService.error({
			title: "Error",
			msg: 'Error while adding Term',
			showClose: true,
			timeout: 3000,
		}));
	}

	 updateTerm(key, term: any) {
		let terms = this.db.list('term_condition');
		// terms.set(key, term);
		terms.set(key+'', term).then(resp => {
			this.toastService.success({
				title: "Success",
				msg: "Term updated!",
				showClose: true,
				timeout: 3000,
			})
		}).catch(() => this.toastService.error({
			title: "Error",
			msg: 'Error while updating Term',
			showClose: true,
			timeout: 3000,
		}));
		return terms;
	}

	removeTerm(key,values) {
		let terms = this.db.list('term_condition');	
		terms.remove(key).then(() => this.toastService.success({
			title: "Success",
			msg: "details deleted successfully!",
			showClose: true,
			timeout: 3000,
		})).catch((err) => this.toastService.error({
			title: "Error",
			msg: 'Error while deleting',
			showClose: true,
			timeout: 3000,
		}));
	}

	updateValues(values:any) {
		let faq = this.db.list('faq');
		// let canWeGet = this.db.list('faq/can_get_answer');
		// let content = this.db.list('faq/content');
		// let heading = this.db.list('faq/heading');
		const allVals =Promise.all([faq.set('can_get_answer',{content:values.content,heading:values.heading}),faq.set('content',values.content1),faq.set('heading',values.heading1)]);
		console.log("Values",{content:values.content,heading:values.heading},values.content1,values.heading1);
		allVals.then(resp => {
			// console.log("Success");
			this.toastService.success({
				title: "Success",
				msg: "details updated successfully!",
				showClose: true,
				timeout: 3000,
			})
		}).catch(err => {
			this.toastService.success({
				title: "Error",
				msg: "Error!",
				showClose: true,
				timeout: 3000,
			})
		})
		return faq;
	}

	addMissionDialogue(missions: any) {
		let dialogRef: MatDialogRef < AddMissionComponent > ;
		dialogRef = this.dialog.open(AddMissionComponent);
		dialogRef.componentInstance.data = missions;
		return dialogRef.afterClosed();
	}

	addPrivacyDialogue(privacyValues: any) {
		let dialogRef: MatDialogRef < AddPrivacyPolicyComponent > ;
		dialogRef = this.dialog.open(AddPrivacyPolicyComponent);
		dialogRef.componentInstance.data = privacyValues;
		return dialogRef.afterClosed();
	}

	public getMission() {
		return this.db.list('mission_vision');
	}
	// testimonials
	public getTestimonials() {
		return this.db.list('testimonial');
	}

	public getPrivacyPolicy() {
		return this.db.list('privacy_policy');
	}

	addMission(mission:any) {
		let missionDb = this.db.list('mission_vision');
		missionDb.push(mission).then(resp => {
			this.toastService.success({
				title: "Success",
				msg: "Mission Added!",
				showClose: true,
				timeout: 3000,
			})
		}).catch(() => this.toastService.error({
			title: "Error",
			msg: 'Error while adding Mission',
			showClose: true,
			timeout: 3000,
		}));
	}

	updateMission(key:any,mission:any) {
		let missionDB = this.db.list('mission_vision');
		missionDB.set(key + '', mission).then(() => this.toastService.success({
			title: "Success",
			msg: "Mission updated!",
			showClose: true,
			timeout: 3000,
		})).catch((err) => this.toastService.error({
			title: "Error",
			msg: 'Error while updating!',
			showClose: true,
			timeout: 3000,
		}));
		return missionDB;
	}

	deleteMission(data: string, data1: any,data2:any) {
		let dialogRef: MatDialogRef < DeleteUserDialogComponent > ;
		dialogRef = this.dialog.open(DeleteUserDialogComponent);
		dialogRef.componentInstance.data = data;
		dialogRef.componentInstance.data1 = data1;
		dialogRef.componentInstance.allValues = data2;
		return dialogRef.afterClosed();
	}

	deletePrivacyPolicy(data: string, data1: any,data2:any) {
		let dialogRef: MatDialogRef < DeleteUserDialogComponent > ;
		dialogRef = this.dialog.open(DeleteUserDialogComponent);
		dialogRef.componentInstance.data = data;
		dialogRef.componentInstance.data1 = data1;
		dialogRef.componentInstance.allValues = data2;
		return dialogRef.afterClosed();
	}

	removeMission(key,allValues) {
		let mission = this.db.list('mission_vision');	
		mission.remove(key).then(() => this.toastService.success({
			title: "Success",
			msg: "details deleted successfully!",
			showClose: true,
			timeout: 3000,
		})).catch((err) => this.toastService.error({
			title: "Error",
			msg: 'Error while deleting',
			showClose: true,
			timeout: 3000,
		}));
	}

	addPolicy(policy:any) {
		let policyDB = this.db.list('privacy_policy');
		policyDB.push(policy).then(resp => {
			this.toastService.success({
				title: "Success",
				msg: "Policy Added!",
				showClose: true,
				timeout: 3000,
			})
		}).catch(() => this.toastService.error({
			title: "Error",
			msg: 'Error while adding Policy',
			showClose: true,
			timeout: 3000,
		}));
	}

	updatePolicy(key:any,policy:any) {
		let missionDB = this.db.list('privacy_policy');
		missionDB.set(key + '', policy).then(() => this.toastService.success({
			title: "Success",
			msg: "Policy updated!",
			showClose: true,
			timeout: 3000,
		})).catch((err) => this.toastService.error({
			title: "Error",
			msg: 'Error while updating!',
			showClose: true,
			timeout: 3000,
		}));
		return missionDB;
	}

	removePolicy(key,allValues) {
		let policy = this.db.list('privacy_policy');	
		policy.remove(key).then(() => this.toastService.success({
			title: "Success",
			msg: "details deleted successfully!",
			showClose: true,
			timeout: 3000,
		})).catch((err) => this.toastService.error({
			title: "Error",
			msg: 'Error while deleting',
			showClose: true,
			timeout: 3000,
		}));
	}
}