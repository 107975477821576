import { Component, OnInit ,Input, Output, EventEmitter, Inject} from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { of } from 'rxjs/observable/of';
import { delay } from 'rxjs/operators';
import { PageService } from 'src/app/AdminPanel/Pages/Services/pages.service';
import { AdminPanelServiceService } from 'src/app/AdminPanel/Service/AdminPanelService.service';

@Component({
   selector: 'app-delete-user-dialog',
   templateUrl: './DeleteDialog.component.html',
   styleUrls: ['./DeleteDialog.component.scss']
   })

export class DeleteUserDialogComponent implements OnInit {
   
   data : any;
   data1 : any;
   allValues:any;
   constructor(public dialogRef : MatDialogRef<DeleteUserDialogComponent>, private service:AdminPanelServiceService,private pagService:PageService){
   } 

   ngOnInit() {
      // console.log("Value",this.data, this.data1)
   }

   yes(){
      console.log("DATA",this.data,this.data1,this.allValues);
      if(this.data.includes('Term')) {
         console.log("DATA 1",this.data1,this.allValues[this.data1].key,this.allValues);
         this.pagService.removeTerm(this.allValues[this.data1].key,this.allValues)
      } else if(this.data.includes('FAQ')) {
         this.pagService.removeFaq(this.data1,this.allValues)
      } else if(this.data.includes('Mission')) {
         this.pagService.removeMission(this.allValues[this.data1].key,this.allValues);
      } else if(this.data.includes('Privacy Policy')) {
         this.pagService.removePolicy(this.allValues[this.data1].key,this.allValues)
      } else if(this.data.includes("Partner")) {
         this.service.deletePartner(this.data1.key)
      } else if(this.data.includes("Window")) {
         this.service.deleteWindow(this.data1.key);
      } else {
         this.service.deleteUser(this.data1)
      }
     
      this.dialogRef.close("yes");
   }
}
