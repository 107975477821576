<div class="counter-wrapper">
	<div *ngIf="days !== '00'" class="counter-item">
		<span class="counter-digit">{{days}}</span>
		<span class="counter-text">Days</span>
	</div>
	<div class="counter-item">
		<span class="counter-digit">{{hours}}</span>
		<span class="counter-text">Hours</span>
	</div>
	<div class="counter-item">
		<span class="counter-digit">{{minutes}}</span>
		<span class="counter-text">Minutes</span>
	</div>
	<div class="counter-item">
		<span class="counter-digit">{{seconds}}</span>
		<span class="counter-text">Seconds</span>
	</div>
</div>
