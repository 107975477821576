import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PageService } from '../../../Pages/Services/pages.service';

@Component({
  selector: 'ms-add-new-client',
  templateUrl: './AddPrivacyPolicy.component.html',
  styleUrls: ['./AddPrivacyPolicy.component.scss']
})
export class AddPrivacyPolicyComponent implements OnInit {

	addMissionForm    : FormGroup;
    alVals:any;
	// pattern1 = /<\/?[a-z][\s\S]*>/i; // regex to check HTML closing tag
	constructor( private formBuilder : FormBuilder,
					 public dialogRef    : MatDialogRef<AddPrivacyPolicyComponent>,
					 public pageService : PageService,
					 @Inject(MAT_DIALOG_DATA) public data: any 
					 ) { }

	ngOnInit() {
		this.pageService.getPrivacyPolicy().snapshotChanges().subscribe(resp => this.saveAll(resp));
		this.addMissionForm = this.formBuilder.group({
			name 	     : [ '',[Validators.required]],
			content: ['',[Validators.required]],
		});
		this.alVals = this.data;
		// console.log("INIT",this.data);
		
	}

	saveAll(resp) {
		const missions = resp.map(a => {
			const data = a.payload.val();
			const key = a.payload.key;
			// console.log("MAP",key,data)
			return {key,...data};           // or {key, ...data} in case data is Obj
		  }).filter(Boolean)[0];
		  // console.log("Vals",faqs);
		  this.data = missions;
	}

	// onFormSubmit method is submit a add new user form.
	onFormSubmit(){
		// let updatedValues = [...this.alVals,this.addTermForm.value];
		console.log("ON SUBMIT",this.data);
		this.pageService.addPolicy(this.addMissionForm.value)
		this.dialogRef.close();
	}
}