<mat-toolbar color="primary" class="embryo-fixed-header">
  <mat-toolbar-row>
    <div class="container">
      <div fxLayoutAlign="space-between">
        <div class="logo-site">
          <embryo-AppLogo></embryo-AppLogo>
        </div>
        <embryo-Menu></embryo-Menu>
        <button class="responsive-toggle" mat-icon-button (click)="toggleSidebar()">
          <i class="material-icons">menu</i>
        </button>
      </div>
    </div>
  </mat-toolbar-row>
</mat-toolbar>
