<div class="container" *ngIf="data">
   <div fxLayout="row wrap" fxLayoutAlign="center center" class="col-gap">
    <div fxFlex.xs="100" fxFlex.sm="100" fxFlex.md="100" fxFlex.lg="50" fxFlex.xl="50" class="p-5">
      <h2 class="font-bold mb-4">
        {{data?.heading}}
      </h2>
      <h4 class="font-italic font-bold mb-4">{{data?.sub_heading}} </h4>
      <p class="mb-4">{{data?.content}}</p>
      <button mat-raised-button color="accent" class="button-lg">Read More</button>
    </div>
    <div fxFlex.xs="100" fxFlex.sm="100" fxFlex.md="100" fxFlex.lg="50" fxFlex.xl="50">
      <img [src]="data.image" width="700" height="700" alt="About Us Image">
    </div>
   </div>
</div>
